// nodeLabels.ts
import { CustomNode } from './types'; // Adjust the import path as necessary
import * as THREE from 'three';

// Function to generate labels for nodes
export function getNodeLabel(text: string, camera: THREE.Camera): THREE.Sprite {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
        throw new Error('Unable to get canvas context');
    }
    const fontSize = 42;
    const padding = 4;

    context.font = `${fontSize}px Arial`;
    const textWidth = context.measureText(text).width;

    canvas.width = textWidth + padding * 2;
    canvas.height = fontSize + padding * 2;

    context.font = `${fontSize}px Arial`;
    //Set background color to black
    context.fillStyle = 'rgba(0, 0, 0, 0.9)';
    context.fillRect(0, 0, canvas.width, canvas.height);

    //Set text color to white
    context.fillStyle = 'white';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(text, canvas.width / 2, canvas.height / 2);

    const texture = new THREE.Texture(canvas);
    const canvasTexture = new THREE.CanvasTexture(canvas);
    const spriteMaterial = new THREE.SpriteMaterial({ map: canvasTexture });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(canvas.width / 10, canvas.height / 10, 1); // Scale the sprite accordingly
    sprite.position.x += 4; // Adjust the position as needed
    sprite.position.y += canvas.height; // Adjust the position as needed
    console.log(`New sprite position: x=${sprite.position.x}, y=${sprite.position.y}`);
    return sprite;
}