import { Node, Link } from "./types";
import { checkProteinExists } from "./data-gen";
import { showLoadingIndicator, hideLoadingIndicator } from "./loading_indicator";

const mode2Controls = document.getElementById("mode2-controls") as HTMLDivElement;
const submitMode2Button = document.getElementById("submitMode2") as HTMLButtonElement;
const proteinListInput = document.getElementById("proteinList") as HTMLTextAreaElement;
export const showIntermediariesCheckbox = document.getElementById("showIntermediaries") as HTMLInputElement;
export const minOverlapInput = document.getElementById("minimumOverlap") as HTMLInputElement;

export function showMode2Controls() {
    mode2Controls.style.display = 'block';
}

export function hideMode2Controls() {
    mode2Controls.style.display = 'none';
}

export function initializeMode2(initializeGraph: (data: { nodes: Node[], links: Link[] }) => void) {
    submitMode2Button.addEventListener("click", async () => {
        const proteinList = proteinListInput.value.trim();
        console.log(proteinList);
        const proteinCheckList = proteinList.split(',').map(protein => protein.trim());
        console.log(proteinCheckList)
        // Validate each protein in the list.
        for (let i = 0; i < proteinCheckList.length; i++) {
            const proteinID = proteinCheckList[i];
            const exists = await checkProteinExists(proteinID); // Adjusted to await inside the loop for sequential checking.
            console.log(`${proteinID}: ${exists}`);

            if (!exists) {
                alert(`Protein ${proteinID} does not exist in the database. Please check your input.`);
                return; // Exit the function early if a protein does not exist.
            }
        }

        if (proteinList) {
            const blob = new Blob([proteinList], { type: 'text/plain' });
            console.log(blob);
            const formData = new FormData();
            formData.append('proteinFile', blob, 'proteinList.txt');
            console.log(formData);
            showLoadingIndicator();
            fetch('http://10.24.88.97:5000/mode2', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(responseData => {
                    console.log(responseData);
                    hideLoadingIndicator();
                    if (responseData.data) {

                        const graphData = responseData.data;
                        console.log('Nodes', graphData.nodes);
                        console.log('Links', graphData.links);

                        initializeGraph({
                            nodes: graphData.nodes,
                            links: graphData.links
                        });
                    }
                    else {
                        console.error('No data received from the server:', responseData.message);
                    }
                })
                .catch(error => {
                    hideLoadingIndicator();
                    console.error('Error:', error);
                });
        };
    });
}

