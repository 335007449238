import neo4j from "neo4j-driver";

export type Node = {
    id: string;
};

export type Link = {
    source: string;
    target: string;
};

async function fetchData(query: string): Promise<{ nodes: Node[]; links: Link[] }> {
    const driver = neo4j.driver(
        'neo4j://10.24.88.204',
        neo4j.auth.basic('neo4j', 'R\"Gnar0k')
    );
    const session = driver.session();

    try {
        const result = await session.run(
            query
        );

        const nodes: Node[] = [];
        const links: Link[] = [];
        const nodeIds = new Map<string, string>();

        console.log(result.records);

        result.records.forEach(record => {
            const sourceNode = record.get('n1');
            const targetNode = record.get('n2');
            const link = record.get('r');

            const sourceID = sourceNode.identity.toString();
            const targetID = targetNode.identity.toString();

            if (!nodeIds.has(sourceID)) {
                nodeIds.set(sourceID, sourceNode.properties.protein_ID);
                nodes.push({ id: sourceNode.properties.protein_ID });
            }

            if (!nodeIds.has(targetID)) {
                nodeIds.set(targetID, targetNode.properties.protein_ID);
                nodes.push({ id: targetNode.properties.protein_ID });
            }


            // if (!nodeIds.has(sourceNode.identity.toString())) {
            //     nodeIds.add(sourceNode.identity.toString());
            //     nodes.push({ id: sourceNode.properties.protein_ID });
            // }

            // if (!nodeIds.has(targetNode.identity.toString())) {
            //     nodeIds.add(targetNode.identity.toString());
            //     nodes.push({ id: targetNode.properties.protein_ID });
            // }

            //add links
            links.push({
                source: nodeIds.get(sourceID)!,
                target: nodeIds.get(targetID)!,
                type: link.type,
                strength: 1000
            });
        });
        return { nodes, links };
    } catch (error) {
        console.log(error);
        return { nodes: [], links: [] };
    } finally {
        await session.close();
        await driver.close();
    }
}

export default fetchData;

// This function checks if a single protein exists in the database.
export async function checkProteinExists(proteinID: any) {
    const query = `MATCH (p:protein {protein_ID: "${proteinID}"}) RETURN p LIMIT 1`;
    const driver = neo4j.driver(
        'neo4j://10.24.88.204',
        neo4j.auth.basic('neo4j', 'R\"Gnar0k')
    );
    const session = driver.session();

    try {
        const result = await session.run(
            query
        );
        return result.records.length > 0; // Returns true if the protein exists, false otherwise
    }
    catch (error) {
        console.log(error);
        return false;
    }
    finally {
        await session.close();
        await driver.close();
    }
}
