import { Node, Link } from './types';
import { showLoadingIndicator, hideLoadingIndicator } from "./loading_indicator";

const proteinNameInput = document.getElementById("proteinName") as HTMLInputElement;
const distanceInput = document.getElementById("distance") as HTMLInputElement;
const limitStrengthInput = document.getElementById("limitStrength") as HTMLInputElement;
const limitTotalInput = document.getElementById("limitTotal") as HTMLInputElement;
const submitMode1Button = document.getElementById("submitMode1") as HTMLButtonElement;
const mode1Controls = document.getElementById("mode1-controls") as HTMLDivElement;
export const showIntermediariesCheckbox1 = document.getElementById("showIntermediaries1") as HTMLInputElement;


let centeredNodeId: string | null = null;



export function initializeMode1(initializeGraph: (data: { nodes: Node[], links: Link[] }) => void) {
    submitMode1Button.addEventListener("click", async () => {
        const proteinName = proteinNameInput.value.trim();
        const distance = parseInt(distanceInput.value);
        const limitStrength = parseFloat(limitStrengthInput.value);
        const limitTotal = parseInt(limitTotalInput.value);

        const checkboxes = document.querySelectorAll('#link-type-filters input[name="linkType"]');
        const allTypes = Array.from(checkboxes).map((checkbox) => (checkbox as HTMLInputElement).value);
        console.log("Submitting_Mode1")
        if (proteinName && distance && limitTotal) {
            showLoadingIndicator();
            try {
                const response = await fetch(`http://10.24.88.97:5000/mode1?proteinName=${proteinName}&distance=${distance}&limitStrength=${limitStrength}&limitTotal=${limitTotal}`);
                const data = await response.json();
                // Pass the retrieved nodes and links data to the updateGraph function
                console.log(data);
                hideLoadingIndicator();
                //Set color for the specifiedNode
                const specifiedNode = data.nodes.find((node: Node) => node.id === proteinName);
                if (specifiedNode) {
                    specifiedNode.color = 'orange';
                    centeredNodeId = specifiedNode.id;
                }
                data.links = data.links.filter((link: Link) => allTypes.includes(link.type as string));
                //Set colors for any link connecting to the specifiedNode
                data.links.forEach((link: Link) => {
                    if (link.source === proteinName || link.target === proteinName) {
                        link.color = 'orange';
                    }
                    // else {
                    //     link.color = 'teal';
                    // }
                });
                //Set colors for the links based on type, using the types from the links
                //get the possible types from the links
                const linkTypes = new Set(data.links.map((link: Link) => link.type));


                initializeGraph(data);

            } catch (error) {
                hideLoadingIndicator();
                console.error('Error fetching data from the server:', error);
                // Handle the error appropriately (e.g., display an error message)
            }
        }
    });
}

export function showMode1Controls() {
    mode1Controls.style.display = 'block';
}

export function hideMode1Controls() {
    mode1Controls.style.display = 'none';
}

