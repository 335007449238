import * as THREE from 'three';
import ForceGraph3D from '3d-force-graph';
import { CustomNode, Node, Link } from './types';
import { getNodeLabel } from './nodeLabels';
import { initializeMode1, showMode1Controls, hideMode1Controls, showIntermediariesCheckbox1 } from './mode1';
import { initializeMode2, showMode2Controls, hideMode2Controls, showIntermediariesCheckbox, minOverlapInput } from './mode2';
import fetchData from "./data-gen";
import { graph } from 'neo4j-driver';

const focusNodeInput = document.getElementById('focusNodeInput') as HTMLInputElement;
const focusNodeButton = document.getElementById('focusNodeButton') as HTMLButtonElement;

const mode1Button = document.getElementById("mode1") as HTMLButtonElement;
const mode2Button = document.getElementById("mode2") as HTMLButtonElement;
const mode3Button = document.getElementById("mode3") as HTMLButtonElement;

let currentMode = '';
const toggleLabelsButton = document.getElementById('toggle-labels') as HTMLButtonElement;
let labelsVisible = true;

mode1Button.addEventListener("click", () => {
    currentMode = 'mode1';
    showMode1Controls();
    hideMode2Controls();
    // hideMode3Controls();
});

mode2Button.addEventListener("click", () => {
    currentMode = 'mode2';
    hideMode1Controls();
    showMode2Controls();
    // hideMode3Controls();
});

mode3Button.addEventListener("click", () => {
    currentMode = 'mode3';
    hideMode1Controls();
    // hideMode2Controls();
    // showMode3Controls();
});

const canvas = document.querySelector("canvas") as HTMLCanvasElement;
const labelsDiv = document.querySelector("#labels") as HTMLDivElement;

const defaultQuery = "MATCH(n) WITH n LIMIT 25 WITH collect(n) AS nodes UNWIND nodes AS n1 UNWIND nodes AS n2 MATCH(n1) - [r] -> (n2) RETURN n1, r, n2";

async function initializeGraph(data: { nodes: any[]; links: any[] }, listeners?: Function[]) {
    console.log("Initializing Graph")
    if (data.nodes.length > 0) {
        console.log(data)
    }
    else {
        data = await fetchData(defaultQuery);
    }
    const graphElement = document.getElementById('3d-graph');
    if (!graphElement) {
        console.error('3D graph element not found!');
        return;
    }
    const Graph = ForceGraph3D()(graphElement)

    const nodePositions: { [nodeId: string]: THREE.Vector3 } = {};

    Graph.onNodeDragEnd((node: any) => {
        nodePositions[node.id] = node.x && node.y && node.z ? new THREE.Vector3(node.x, node.y, node.z) : new THREE.Vector3();
    });

    const customNodes: CustomNode[] = data.nodes
        .filter((node: any) => node !== undefined)
        .map((node: CustomNode) => ({
            id: node.id,
            color: node.color,
            alwaysVisible: node.alwaysVisible,
        }));

    const maxStrength = 1000; // The known maximum strength for scaling

    // data.links.forEach(link => {
    //     link.opacity = ((link.strength || 0) / maxStrength) * 0.9 + 0.25; // Ensures a min opacity of 0.25
    // });
    // console.log(data.links);

    Graph.graphData({
        nodes: customNodes,
        links: data.links,
    })
        .linkVisibility((link: any) => {
            const selectedTypes = getSelectedLinkTypes();
            return selectedTypes.includes(link.type);
        })
    // .linkCurvature((link: any) => calculateCurvature(link))

    // Graph
    //     .d3Force('link')
    //     .distance((link: any) => {
    //         // const strength = link.strength / 1000 || 0;
    //         const strength = 1;
    //         const colordict: { [key: string]: number } = {
    //             "grey": 1,
    //             "blue": 1,
    //             "green": 2,
    //             "yellow": 3,
    //             "orange": 4,
    //             "red": 5,
    //         };
    //         const color = colordict[link.color] || 1;
    //         // set the link distance based on the strength and color
    //         const distance_factor = strength * color * 20;
    //         return 100 / distance_factor;
    //     });
    // //Set the link widths based on the link distance
    // Graph.linkWidth((link: any) => {
    //     const strength = link.strength / 1000 || 0;
    //     const colordict: { [key: string]: number } = {
    //         "blue": 1,
    //         "green": 2,
    //         "yellow": 3,
    //         "orange": 4,
    //         "red": 5,
    //     };
    //     const color = colordict[link.color] || 1;
    //     return 1 / (strength * color);
    // });

    Graph.nodeThreeObject((node: any) => {
        const sprite = getNodeLabel(node.id, Graph.camera());
        const group = new THREE.Group();

        // Create a new Three.js Mesh for the node
        //if the node has the alwaysVisible property, set the size to 3, else 1
        const geometry = new THREE.SphereGeometry(node.alwaysVisible ? 3 : 1);
        const material = new THREE.MeshBasicMaterial({ color: node.color || 0x00ff00 }); // Use the node's color if specified, otherwise use a default color
        const mesh = new THREE.Mesh(geometry, material);

        // Add the mesh and sprite to the group
        group.add(mesh);
        const y_pos = node.alwaysVisible ? 6 : 2;
        const labelPosition = new THREE.Vector3(0, y_pos, 0);
        sprite.position.copy(labelPosition);
        sprite.visible = labelsVisible;
        group.add(sprite);

        node.__label = sprite;
        return group;
    });
    function toggleLabels() {
        labelsVisible = !labelsVisible;
        Graph.graphData().nodes.forEach((node: any) => {
            if (node.__label) {
                node.__label.visible = labelsVisible;
            }
        });
    }
    toggleLabelsButton.addEventListener('click', toggleLabels);
    document.querySelectorAll('#link-type-filters input[name="linkType"]').forEach(checkbox => {
        checkbox.addEventListener('change', () => {
            updateLinkVisibility(Graph);
        });
    });
    // Add event listener for the focus node button

    focusNodeButton.addEventListener('click', () => {
        const proteinID = focusNodeInput.value.trim();
        if (proteinID) {
            const node = Graph.graphData().nodes.find((node: any) => node.id === proteinID);
            if (node) {
                if (node.x !== undefined && node.y !== undefined && node.z !== undefined) {
                    const distance = 200; // Adjust this value to control the distance from the node
                    const offsetX = node.x - (distance / 2);
                    const offsetY = node.y + (distance / 2); // Offset the camera above the node
                    const offsetZ = node.z + distance; // Offset the camera towards the viewer
                    Graph.cameraPosition(
                        { x: offsetX, y: offsetY, z: offsetZ },
                        undefined,
                        1000
                    );
                } else {
                    alert(`Protein ${proteinID} does not have valid coordinates.`);
                }
            } else {
                alert(`Protein ${proteinID} not found in the current graph.`);
            }
        }
    });
    //if mode1 is active, add event listeners for the mode1 controls
    if (document.getElementById("mode1-controls")?.style.display !== 'none') {
        showIntermediariesCheckbox1.addEventListener('change', () => {
            updateLinkVisibility(Graph);
        });
    }
    //if mode2 is active, add event listeners for the mode2 controls
    if (document.getElementById("mode2-controls")?.style.display !== 'none') {
        showIntermediariesCheckbox.addEventListener('change', () => {
            updateLinkVisibility(Graph);
        });
        minOverlapInput.addEventListener('input', () => {
            updateLinkVisibility(Graph);
        });
    };
    updateLinkVisibility(Graph);
}

function updateLinkVisibility(graph: any) {
    // console.log("Updating Visibility");
    const isMode1Active = document.getElementById("mode1-controls")?.style.display !== 'none';
    const isMode2Active = document.getElementById("mode2-controls")?.style.display !== 'none';
    const selectedLinkTypes = getSelectedLinkTypes();
    const minOverlap = isMode2Active ? parseInt((document.getElementById("minimumOverlap") as HTMLInputElement)?.value || '0') : 0;
    const showIntermediaries = isMode1Active
        ? (document.getElementById("showIntermediaries1") as HTMLInputElement)?.checked
        : isMode2Active
            ? (document.getElementById("showIntermediaries") as HTMLInputElement)?.checked
            : true;
    graph.linkVisibility((link: any) => {
        // Check if the link type is selected
        const isTypeSelected = selectedLinkTypes.includes(link.type);

        // Check if the link occurrence meets the minimum overlap requirement
        const linkOccurrence = link.occurrence !== undefined ? link.occurrence : 0;
        const meetsOverlapRequirement = minOverlap === 0 ? linkOccurrence >= 0 : linkOccurrence > minOverlap;

        // Check if intermediaries should be shown or if the link is not an intermediary
        const isIntermediaryVisible = showIntermediaries || link.color !== 'grey';

        // Combine the conditions based on the active mode
        const isVisible = (isMode1Active || isMode2Active)
            ? isTypeSelected && meetsOverlapRequirement && isIntermediaryVisible
            : isTypeSelected;
        link.visibile = isVisible;
        return isVisible;
    });
    updateNodeVisibility(graph);
    graph.refresh();
    // graph.zoomToFit(400);

}
function updateNodeVisibility(graph: any) {
    // console.log("Updating Node Visibility");
    // console.log(graph.graphData().nodes);
    // Get the list of visible node IDs based on the visible links
    const visibleNodeIds = new Set<string>();
    graph.graphData().links.forEach((link: any) => {
        if (graph.linkVisibility()(link)) {
            const sourceId = link.source.id || link.source;
            const targetId = link.target.id || link.target;
            visibleNodeIds.add(sourceId);
            visibleNodeIds.add(targetId);
        }
    });

    //console.log(visibleNodeIds);

    // Update the visibility of each node based on the visible node IDs
    graph.graphData().nodes.forEach((node: any) => {
        const visible = node.alwaysVisible || visibleNodeIds.has(node.id);

        if (node.__label) {
            node.__label.visible = visible && labelsVisible;
        }

        node.visible = visible;
    });

    graph.nodeVisibility((node: any) => node.visible);
    // adjustVisibleNodesAndCamera(graph);
}

function adjustVisibleNodesAndCamera(graph: any) {
    const visibleNodes = graph.graphData().nodes.filter((node: any) => node.visible);
    console.log(visibleNodes);
    if (visibleNodes.length > 0) {
        // Calculate the center of all visible nodes
        //just iterate through and console log the positions
        let centerX = 0;
        let centerY = 0;
        let centerZ = 0;
        visibleNodes.forEach((node: any) => {
            console.log(node.x, node.y, node.z);
            centerX += node.x;
            centerY += node.y;
            centerZ += node.z;
        });

        const distance = 200; // Adjust this value to control the distance from the center
        const cameraPosition = {
            x: centerX,
            y: centerY,
            z: centerZ + distance,
        };

        graph.cameraPosition(cameraPosition, { x: centerX, y: centerY, z: centerZ }, 1000);
    }
}

// function updateNodeVisibility(graph: any) {
//     graph.nodeVisibility((node: any) => {
//         const connectedLinks = graph.graphData().links.filter((link: any) => link.source === node.id || link.target === node.id);
//         const visible = connectedLinks.some((link: any) => link.visible);

//         if (node.__label) {
//             node.__label.visible = visible && labelsVisible;
//         }
//         return visible;
//     }
//     );
// }

// function updateLinkVisibility(graph: any) {
//     console.log("Updating Visibility");
//     const isMode2Active = document.getElementById("mode2-controls")?.style.display !== 'none';
//     console.log(isMode2Active)
//     const selectedLinkTypes = getSelectedLinkTypes();
//     const minOverlap = isMode2Active ? parseInt((document.getElementById("minOverlap") as HTMLInputElement)?.value || '0') : 0;
//     const showIntermediaries = isMode2Active ? (document.getElementById("showIntermediaries") as HTMLInputElement)?.checked : true;

//     graph.linkVisibility((link: any) => {
//         //First we apply type filtering in any mode:
//         let isVisible = selectedLinkTypes.includes(link.type);
//         //In Mode 2, we apply mode2 filters
//         if (isMode2Active) {
//             // const minOverlap = parseInt((document.getElementById("minOverlap") as HTMLInputElement)?.value || '0');
//             // const showIntermediaries = (document.getElementById("showIntermediaries") as HTMLInputElement)?.checked;

//             const linkOccurrence = parseInt(link.occurrence || '0')

//             const overlapVisible = linkOccurrence > minOverlap;
//             const intermediariesVisible = showIntermediaries || link.color !== 'grey';

//             isVisible = isVisible && overlapVisible && intermediariesVisible;
//         }
//         return isVisible;
//     });
//     graph.refresh();
// }

function getSelectedLinkTypes(): string[] {
    const checkboxes = document.querySelectorAll('#link-type-filters input[name="linkType"]:checked');
    const selectedTypes: string[] = Array.from(checkboxes).map((checkbox) => {
        // Cast each checkbox to HTMLInputElement to access the `value` property
        return (checkbox as HTMLInputElement).value;
    });
    return selectedTypes;
}
function hashStringToNumber(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const character = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

function calculateCurvature(link: any) {
    const hash = hashStringToNumber(link.type);
    const baseCurvature = 0.1; // Start with a base curvature
    // Adjust curvature based on the hash; ensure it remains within reasonable bounds
    return baseCurvature + Math.abs(hash % 10) * 0.05; // Example adjustment
}

// Add event listener to your query submit button
// const queryButton = document.getElementById('queryButton');
// if (queryButton) {
//     queryButton.addEventListener('click', async () => {
//         // Get the query from the input field
//         const queryInput = document.getElementById('queryInput') as HTMLInputElement;
//         // Call createGraph with the query
//         if (queryInput) {
//             const query = queryInput.value;
//             const data = await fetchData(query);
//             initializeGraph(data)
//                 .then(() => console.log('Graph updated successfully.'))
//                 .catch(err => console.error('Error updating graph:', err));
//         }
//         else {
//             console.error('Query input not found!');
//         }
//     });
// } else {
//     console.error('Query button not found!');
// }

initializeGraph({ nodes: [], links: [] }).then(() => console.log('Graph initialized successfully.')).catch(err => console.error('Error initializing graph:', err));
initializeMode1(initializeGraph);
initializeMode2(initializeGraph);
